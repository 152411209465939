div .centerImage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  pointer-events: none;
  user-select: none;
}

div .colourfulBG {
  max-width: 770px;

  @media (max-height: 1000px) {
    max-width: 667px;
  }
  @media (max-height: 814px) {
    max-width: 560px;
  }
  @media (min-height: 1270px) {
    max-width: 950px;
  }
}

div .frame {
  max-width: 815px;
  max-height: 925px;

  @media (max-height: 1000px) {
    max-width: 703px;
    max-height: 807px;
  }
  @media (max-height: 814px) {
    max-width: 590px;
    max-height: 677px;
  }
  @media (min-height: 1270px) {
    max-width: 990px;
    max-height: 1130px;
  }
}

div .rollsRemaining {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;

  @media (max-width: 870px) {
    z-index: 0;
  }

  @media (min-height: 1270px) {
    left: 72px;
    top: 43px;
  }
}

div .setingsImage {
  position: fixed;
  right: 26px;
  top: 26px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: rotate(5deg);
  }

  &:active {
    opacity: 0.6;
  }
}

div .score {
  position: absolute;
  z-index: 100;

  &Left {
    left: -325px;

    @media (max-width: 1130px) {
      left: -245px;
    }

    @media (min-height: 1270px) {
      top: 25%;
      left: -360px;
      transform: scale(1.4);
    }
  }
  &Right {
    right: -325px;

    @media (max-width: 1130px) {
      right: -245px;
    }

    @media (min-height: 1270px) {
      top: 25%;
      right: -360px;
      transform: scale(1.4);
    }
  }
}

div .board {
  max-width: 450px;
  max-height: 710px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  user-select: auto;

  position: absolute;
  top: 45.3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);

  @media (max-height: 1000px) {
    max-width: 385px;
    top: 40.6%;
  }

  @media (max-height: 814px) {
    max-width: 324px;
    top: 34%;
  }

  @media (min-height: 1270px) {
    max-width: 590px;
    top: 53%;
  }

  canvas {
    z-index: 9;
    @media (min-height: 1270px) {
      position: relative;
      top: -136px;
    }
  }
}

div .wall {
  position: absolute;
  bottom: 95px;
  z-index: 99;
  @media (max-height: 1000px) {
    width: 27px;
  }

  @media (max-height: 814px) {
    width: 23px;
    bottom: 77px;
  }

  @media (min-height: 1270px) {
    width: 43px;
    bottom: 100px;
  }

  &Left {
    left: 0;
  }
  &Right {
    right: 0;
  }
}
div .pointLines {
  position: absolute;
  bottom: 0px;
  z-index: 99;
  width: 362px;

  @media (max-height: 1000px) {
    width: 311px;
    height: 52px;
  }

  @media (max-height: 814px) {
    width: 261px;
    height: 50px;
  }

  @media (min-height: 1270px) {
    width: 474px;
    height: 70px;
  }
}

div .boardDots {
  position: absolute;
  bottom: 90px;
  z-index: 99;

  @media (max-height: 1000px) {
    width: 320px;
    bottom: 80px;
  }

  @media (max-height: 814px) {
    width: 272.9px;
    bottom: 70px;
    height: 390px;
  }

  @media (min-height: 1270px) {
    width: 485px;
    bottom: 100px;
  }
}

div .boardBackground {
  position: absolute;
  bottom: 0px;
  z-index: 1;
  width: 454px;
  height: 772px;
  @media (max-height: 1000px) {
    width: 392px;
    height: 682px;
  }
  @media (max-height: 814px) {
    width: 330px;
    height: 576px;
  }
  @media (min-height: 1270px) {
    width: 598px;
    height: 980px;
  }
}

div .scores {
  position: absolute;
  bottom: -102px;
  left: -15px;
  z-index: 9;
}

div .others {
  position: absolute;
  z-index: 9;
  width: 40px;

  &One {
    left: -23px;
    bottom: -89px;
    transform: rotate(23deg);
  }
  &Two {
    left: 29px;
    bottom: -103px;
    transform: rotate(13deg);
  }
  &Three {
    left: 87px;
    bottom: -112px;
    transform: rotate(8deg);
  }
  &Four {
    left: 148px;
    bottom: -119px;
    transform: rotate(6.4deg);
  }
  &Five {
    left: 214px;
    bottom: -123px;
    transform: rotate(0deg);
  }
  &Six {
    left: 281px;
    bottom: -122px;
    transform: rotate(-3.4deg);
  }
  &Seven {
    left: 340px;
    bottom: -116px;
    transform: rotate(-8.2deg);
  }
  &Eight {
    left: 397px;
    bottom: -105px;
    transform: rotate(-13.9deg);
  }
  &Nine {
    left: 450px;
    bottom: -90px;
    transform: rotate(339deg);
  }
}

div .numbers {
  position: absolute;
  top: 58px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px;
  z-index: 10;

  @media (max-height: 1000px) {
    top: 135px;
    padding: 0px 15px;
  }

  @media (max-height: 814px) {
    top: 222px;
    padding: 0px 13px;
  }

  @media (min-height: 1270px) {
    top: -127px;
    padding: 0px 30px;
  }

  .ball {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    img {
      @media (max-height: 1000px) {
        width: 28px;
        margin: 3px;
      }

      @media (max-height: 814px) {
        width: 20px;
        margin: 4px;
      }

      @media (min-height: 1270px) {
        width: 34px;
        margin: 0px;
      }
    }
  }

  span {
    font-weight: 400;
    font-size: 28px;
    text-align: center;
    color: #d4b291;
    padding: 0;

    @media (max-height: 814px) {
      font-size: 22px;
    }

    @media (min-height: 1270px) {
      font-size: 30px;
    }
  }
}
