div .rollsRemainingContainer {
  width: 307px;
  height: 186px;

  background: url('../../assets/RollsRemaining.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1130px) {
    width: 250px;
  }

  @media (min-height: 1270px) {
    transform: scale(1.4);
  }
}

div .text {
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 0;
  text-shadow:
    -3px 2px 0px #bd4036,
    0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 1130px) {
    font-size: 26px;
  }

  &Number {
    font-size: 48px;
  }
}
