div .mainConainer {
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
  background-color: #dff6ff;
  background: url('./assets/BlueBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  user-select: none;
  overflow: auto;

  @media (max-height: 713px) {
    min-height: 713px;
  }

  @media (min-height: 1270px) {
    background-position: 0px -10px;
  }
}
