div .others {
  position: absolute;
  z-index: 9;
  width: 40px;

  @media (max-height: 1000px) {
    width: 35px;
  }
  @media (max-height: 814px) {
    width: 25px;
  }
  @media (min-height: 1270px) {
    width: 44px;
  }

  &1 {
    left: -28px;
    bottom: -89px;
    transform: rotate(23deg);
    @media (max-height: 1000px) {
      bottom: -70px;
    }
    @media (max-height: 814px) {
      bottom: -53px;
      left: -24px;
    }
    @media (min-height: 1270px) {
      left: -17px;
      bottom: -81px;
    }
  }
  &2 {
    left: 23px;
    bottom: -103px;
    transform: rotate(13deg);
    @media (max-height: 1000px) {
      left: 15px;
      bottom: -84px;
    }
    @media (max-height: 814px) {
      bottom: -63px;
      left: 14px;
    }
    @media (min-height: 1270px) {
      left: 47px;
      bottom: -99px;
    }
  }
  &3 {
    left: 80px;
    bottom: -112px;
    transform: rotate(8deg);
    @media (max-height: 1000px) {
      left: 65px;
      bottom: -93px;
    }
    @media (max-height: 814px) {
      left: 55px;
      bottom: -70px;
    }
    @media (min-height: 1270px) {
      left: 117px;
      bottom: -109px;
    }
  }
  &4 {
    left: 140px;
    bottom: -119px;
    transform: rotate(6.4deg);
    @media (max-height: 1000px) {
      left: 118px;
      bottom: -99px;
    }
    @media (max-height: 814px) {
      left: 100px;
      bottom: -75px;
    }
    @media (min-height: 1270px) {
      left: 191px;
      bottom: -118px;
    }
  }
  &5 {
    left: 207px;
    bottom: -123px;
    transform: rotate(0deg);
    @media (max-height: 1000px) {
      left: 176px;
      bottom: -102px;
    }
    @media (max-height: 814px) {
      left: 151px;
      bottom: -79px;
    }
    @media (min-height: 1270px) {
      left: 275px;
      bottom: -123px;
    }
  }
  &6 {
    left: 271px;
    bottom: -122px;
    transform: rotate(-3.4deg);
    @media (max-height: 1000px) {
      left: 233px;
      bottom: -101px;
    }
    @media (max-height: 814px) {
      left: 198px;
      bottom: -78px;
    }
    @media (min-height: 1270px) {
      left: 355px;
      bottom: -122px;
    }
  }
  &7 {
    left: 329px;
    bottom: -116px;
    transform: rotate(-8.2deg);
    @media (max-height: 1000px) {
      left: 284px;
      bottom: -97px;
    }
    @media (max-height: 814px) {
      left: 244px;
      bottom: -74px;
    }
    @media (min-height: 1270px) {
      left: 427px;
      bottom: -115px;
    }
  }
  &8 {
    left: 385px;
    bottom: -105px;
    transform: rotate(-13.9deg);
    @media (max-height: 1000px) {
      left: 333px;
      bottom: -86px;
    }
    @media (max-height: 814px) {
      left: 284px;
      bottom: -65px;
    }
    @media (min-height: 1270px) {
      left: 496px;
      bottom: -102px;
    }
  }
  &9 {
    left: 437px;
    bottom: -90px;
    transform: rotate(339deg);
    @media (max-height: 1000px) {
      left: 378px;
      bottom: -70px;
    }
    @media (max-height: 814px) {
      left: 322px;
      bottom: -53px;
    }
    @media (min-height: 1270px) {
      left: 561px;
      bottom: -83px;
    }
  }
}
