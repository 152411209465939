@font-face {
  font-family: 'PongGame';
  src:
    url('./fonts//fonnts.com-Pong-Game.ttf') format('truetype'),
    url('./fonts/fonnts.com-Pong-Game.otf') format('otf');
}

body {
  margin: 0;
  font-family: 'PongGame', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
}

* {
  box-sizing: border-box;
}
