div .finalResultsWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}

div .finalResultsBackground {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10.199999809265137px);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  animation: show 0.5s cubic-bezier(0.11, 0, 0.5, 0) 1.5s 1 normal forwards;
  opacity: 0;
}

div .finalResultsModal {
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../../assets/ResultsBackground.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: entrance 0.5s cubic-bezier(0.42, 0, 0.57, 1.27) 1.5s 1 normal
    forwards;
  opacity: 0;

  @media (max-height: 830px) {
    background-size: 1200px;
    background-position: 50% calc(50% - -11px);
  }

  @media (max-height: 650px) {
    background-size: 1088px;
    background-position: 50% calc(50% + 20px);
  }

  button {
    border-radius: 10px;
    padding: 10px 24px;
    width: fit-content;
    height: 55px;
    font-family: 'PongGame';
    font-weight: 400;
    font-size: 36px;
    color: #fff;
    outline: none;
    transition: 0.2s;
    box-shadow:
      inset 3px -2px 4px 0 rgba(103, 103, 103, 0.25),
      inset 0 4px 2px 0 rgba(255, 255, 255, 0.5),
      inset 3px -2px 4px 0 rgba(144, 144, 144, 0.25),
      inset -2px 0 4px 0 rgba(92, 92, 92, 0.25),
      1px 4px 0 0 #245324;
    background: linear-gradient(180deg, #53b333 0%, #244d16 100%);

    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(1);
    }
  }
}

div .score {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  margin-bottom: 11px;

  @media (max-height: 830px) {
    gap: 15px;
    margin-bottom: 0px;
  }
  @media (max-height: 650px) {
    gap: 10px;
    margin-bottom: -20px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes entrance {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
