div .headerContainer {
  position: absolute;
  z-index: 50999;
  top: -52px;

  @media (max-height: 1000px) {
    top: 42px;
    padding: 0px 25px;
  }

  @media (max-height: 814px) {
    top: 142px;
    padding: 0px 13px;
  }

  @media (min-height: 1270px) {
    top: -255px;
    padding: 0px 13px;
  }

  button {
    width: 317px;
    height: 91px;
    z-index: 9999999999;
    opacity: 1;
    transition: 0.2s cubic-bezier(0, 0.39, 0, 0.99);

    background: url('../../assets/PlinkoLogo.png');
    background-size: 276px;
    background-repeat: no-repeat;
    background-position: 50% 0%;

    @media (max-height: 1000px) {
      width: 260px;
      height: 85px;
      background-size: 255px;
    }
    @media (max-height: 814px) {
      width: 215px;
      height: 70px;
      background-size: 213px;
    }
    @media (min-height: 1270px) {
      width: 350px;
      height: 113px;
      background-size: 335px;
    }

    &:active {
      background: url('../../assets/PlinkoLogoPressed.png');
      background-size: 276px;
      background-repeat: no-repeat;
      background-position: 50% 70%;
      @media (min-height: 1270px) {
        background-size: 335px;
        background-position: 50% 50%;
      }
      @media (max-height: 1000px) {
        background-size: 255px;
      }
      @media (max-height: 814px) {
        background-size: 213px;
      }
    }
  }
}
