div .scores {
  &Container {
    position: absolute;
    z-index: 9;
    bottom: -20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 48px;
    color: #fff;
    text-shadow:
      -1px 0px 0px rgba(0, 0, 0, 0.25),
      -2px 0px 0 rgba(0, 0, 0, 0.15),
      0px 0px 1px rgba(255, 255, 255, 0.25);

    @media (max-height: 1000px) {
      bottom: -2%;
      font-size: 38px;
    }

    @media (max-height: 814px) {
      bottom: -2%;
      font-size: 26px;
    }

    @media (min-height: 1270px) {
      bottom: -8px;
      font-size: 48px;
    }
  }

  &Span {
    &1 {
      position: absolute;
      transform: rotate(-67deg);
      &One {
        top: -9px;
        left: -5px;
        @media (max-height: 1000px) {
          top: -10px;
          left: -8px;
        }
        @media (max-height: 814px) {
          top: -10px;
          left: -6px;
        }
        @media (min-height: 1270px) {
          top: -8px;
          left: 6px;
        }
      }
      &Two {
        top: -5px;
        left: -16px;
        @media (max-height: 1000px) {
          left: -17px;
        }
        @media (max-height: 814px) {
          left: -12px;
        }
        @media (min-height: 1270px) {
          top: 2px;
          left: -3px;
          font-size: 44px;
        }
      }
      &Three {
        top: -5px;
        left: -22px;
        font-size: 44px;
        @media (max-height: 1000px) {
          left: -21px;
          font-size: 36px;
        }
        @media (max-height: 814px) {
          left: -14px;
          font-size: 24px;
        }
        @media (min-height: 1270px) {
          top: 4px;
          left: -5px;
          font-size: 39px;
        }
      }
    }

    &2 {
      position: absolute;
      top: 0;
      transform: rotate(-75deg);
      &One {
        left: 41px;
        @media (max-height: 1000px) {
          top: -4px;
          left: 33px;
        }
        @media (max-height: 814px) {
          top: -4px;
          left: 29px;
        }
        @media (min-height: 1270px) {
          left: 63px;
        }
      }
      &Two {
        top: 6px;
        left: 26px;
        @media (max-height: 1000px) {
          left: 21px;
        }
        @media (max-height: 814px) {
          left: 20px;
        }
        @media (min-height: 1270px) {
          top: 6px;
          left: 55px;
        }
      }
      &Three {
        top: 0px;
        left: 17px;
        @media (max-height: 1000px) {
          left: 14px;
        }
        @media (max-height: 814px) {
          left: 15px;
        }
        @media (min-height: 1270px) {
          top: 11px;
          left: 50px;
          font-size: 40px;
        }
      }
    }

    &3 {
      position: absolute;
      top: 0;
      transform: rotate(-75deg);
      &One {
        left: 94px;
        @media (max-height: 1000px) {
          left: 78px;
          top: 3px;
        }
        @media (max-height: 814px) {
          left: 67px;
          top: 3px;
        }
        @media (min-height: 1270px) {
          left: 131px;
          top: 8px;
        }
      }
      &Two {
        top: 8px;
        left: 80px;
        @media (max-height: 1000px) {
          left: 68px;
        }
        @media (max-height: 814px) {
          left: 60px;
        }
        @media (min-height: 1270px) {
          top: 9px;
          left: 119px;
        }
      }
      &Three {
        top: 8px;
        left: 69px;
        @media (max-height: 1000px) {
          left: 59px;
        }
        @media (max-height: 814px) {
          left: 54px;
        }
        @media (min-height: 1270px) {
          top: 13px;
          left: 112px;
          font-size: 44px;
        }
      }
    }

    &4 {
      position: absolute;
      top: 0;
      transform: rotate(-82deg);
      &One {
        left: 147px;
        @media (max-height: 1000px) {
          left: 124px;
          top: 6px;
        }
        @media (max-height: 814px) {
          left: 107px;
          top: 6px;
        }
        @media (min-height: 1270px) {
          left: 197px;
          top: 12px;
        }
      }
      &Two {
        top: 10px;
        left: 133px;
        @media (max-height: 1000px) {
          left: 115px;
        }
        @media (max-height: 814px) {
          left: 99px;
        }
        @media (min-height: 1270px) {
          top: 12px;
          left: 187px;
        }
      }
      &Three {
        top: 10px;
        left: 125px;
        @media (max-height: 1000px) {
          left: 105px;
        }
        @media (max-height: 814px) {
          left: 94px;
        }
        @media (min-height: 1270px) {
          top: 14px;
          left: 180px;
          font-size: 44px;
        }
      }
    }

    &5 {
      position: absolute;
      transform: rotate(-90deg);
      top: 0;
      &One {
        right: 200px;
        @media (max-height: 1000px) {
          right: 172px;
          top: 8px;
        }
        @media (max-height: 814px) {
          right: 149px;
          top: 8px;
        }
        @media (min-height: 1270px) {
          right: 269px;
          top: 14px;
        }
      }
      &Two {
        top: 12px;
        right: 190px;
        @media (max-height: 1000px) {
          right: 163px;
        }
        @media (max-height: 814px) {
          right: 143px;
        }
        @media (min-height: 1270px) {
          top: 12px;
          right: 258px;
        }
      }
      &Three {
        top: 12px;
        right: 178px;
        @media (max-height: 1000px) {
          right: 155px;
        }
        @media (max-height: 814px) {
          right: 136px;
        }
        @media (min-height: 1270px) {
          font-size: 46px;
          top: 14px;
          right: 248px;
        }
      }
    }

    &6 {
      position: absolute;
      top: 0;
      transform: rotate(-98deg);
      &One {
        right: 143px;
        @media (max-height: 1000px) {
          right: 121px;
          top: 6px;
        }
        @media (max-height: 814px) {
          right: 105px;
          top: 6px;
        }
        @media (min-height: 1270px) {
          right: 196px;
          top: 11px;
        }
      }
      &Two {
        top: 10px;
        right: 129px;
        @media (max-height: 1000px) {
          right: 111px;
        }
        @media (max-height: 814px) {
          right: 98px;
        }
        @media (min-height: 1270px) {
          top: 10px;
          right: 181px;
        }
      }
      &Three {
        top: 10px;
        right: 121px;
        @media (max-height: 1000px) {
          right: 103px;
        }
        @media (max-height: 814px) {
          right: 92px;
        }
        @media (min-height: 1270px) {
          font-size: 44px;
          top: 13px;
          right: 175px;
        }
      }
    }

    &7 {
      position: absolute;
      top: 0;
      transform: rotate(-102deg);
      &One {
        right: 91px;
        @media (max-height: 1000px) {
          right: 76px;
          top: 2px;
        }
        @media (max-height: 814px) {
          right: 66px;
          top: 2px;
        }
        @media (min-height: 1270px) {
          right: 129px;
          top: 6px;
        }
      }
      &Two {
        top: 8px;
        right: 77px;
        @media (max-height: 1000px) {
          right: 66px;
        }
        @media (max-height: 814px) {
          right: 59px;
        }
        @media (min-height: 1270px) {
          top: 8px;
          right: 119px;
        }
      }
      &Three {
        top: 8px;
        right: 67px;
        @media (max-height: 1000px) {
          right: 57px;
        }
        @media (max-height: 814px) {
          right: 53px;
        }
        @media (min-height: 1270px) {
          font-size: 44px;
          top: 11px;
          right: 112px;
        }
      }
    }

    &8 {
      position: absolute;
      top: 0;
      transform: rotate(-106deg);
      &One {
        right: 38px;
        @media (max-height: 1000px) {
          right: 29px;
        }
        @media (max-height: 814px) {
          right: 28px;
        }
        @media (min-height: 1270px) {
          right: 64px;
        }
      }
      &Two {
        top: 3px;
        right: 19px;
        @media (min-height: 1270px) {
          top: 3px;
          right: 53px;
        }
      }
      &Three {
        top: 1px;
        right: 14px;
        @media (max-height: 1000px) {
          right: 11px;
        }
        @media (max-height: 814px) {
          right: 15px;
        }
        @media (min-height: 1270px) {
          font-size: 40px;
          top: 9px;
          right: 48px;
        }
      }
    }

    &9 {
      position: absolute;
      transform: rotate(-113deg);
      &One {
        top: -9px;
        right: -10px;
        @media (max-height: 1000px) {
          right: -12px;
        }
        @media (max-height: 814px) {
          right: -8px;
        }
        @media (min-height: 1270px) {
          top: -8px;
          right: 3px;
        }
      }
      &Two {
        top: -5px;
        right: -23px;
        @media (max-height: 1000px) {
          right: -23px;
        }
        @media (max-height: 814px) {
          right: -16px;
        }
        @media (min-height: 1270px) {
          top: -0px;
          right: -6px;
          font-size: 44px;
        }
      }
      &Three {
        top: -5px;
        right: -30px;
        font-size: 44px;
        @media (max-height: 1000px) {
          font-size: 34px;
          right: -27px;
        }
        @media (max-height: 814px) {
          font-size: 24px;
          right: -19px;
        }
        @media (min-height: 1270px) {
          font-size: 34px;
          top: 6px;
          right: -7px;
        }
      }
    }
  }
}
