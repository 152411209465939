div .scoreContainer {
  position: relative;
  width: 272px;
  height: 207px;

  background: url('../../assets/ScoreTable.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1130px) {
    width: 240px;
  }

  .pointsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .point {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    position: absolute;
    background: url('../../assets/ScoreDot.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition: 0.3s ease-in-out;

    &.active {
      filter: brightness(1);
      animation: pulseAnimation 1s infinite;

      &::after {
        content: '';
        position: absolute;
        top: 27px;
        left: 27px;
        width: 10px;
        height: 10px;
        background-color: #ffa500;
        border-radius: 50%;
        opacity: 0.6;
        filter: blur(1.2px);

        @media (max-width: 1130px) {
          top: 25px;
          left: 25px;
          width: 8px;
          height: 8px;
        }
      }
    }

    @media (max-width: 1130px) {
      width: 58px;
      height: 58px;
    }
    &1 {
      top: 10px;
      left: 11px;
      @media (max-width: 1130px) {
        top: 19px;
        left: 8px;
      }
    }
    &2 {
      top: 7px;
      left: 31px;
      @media (max-width: 1130px) {
        top: 16px;
        left: 26px;
      }
    }
    &3 {
      top: 5px;
      left: 52px;
      @media (max-width: 1130px) {
        top: 14px;
        left: 45px;
      }
    }
    &4 {
      top: 2px;
      left: 72px;
      @media (max-width: 1130px) {
        top: 12px;
        left: 63px;
      }
    }
    &5 {
      top: 0px;
      left: 93px;
      @media (max-width: 1130px) {
        top: 10px;
        left: 81px;
      }
    }
    &6 {
      top: 0;
      left: 113px;
      @media (max-width: 1130px) {
        top: 10px;
        left: 99px;
      }
    }
    &7 {
      top: 2px;
      left: 134px;
      @media (max-width: 1130px) {
        top: 12px;
        left: 117px;
      }
    }
    &8 {
      top: 4px;
      left: 154px;
      @media (max-width: 1130px) {
        top: 14px;
        left: 135px;
      }
    }
    &9 {
      top: 6px;
      left: 174px;
      @media (max-width: 1130px) {
        top: 17px;
        left: 153px;
      }
    }
    &10 {
      top: 10px;
      left: 195px;
      @media (max-width: 1130px) {
        top: 20px;
        left: 172px;
      }
    }
    &11 {
      top: 31px;
      left: 204px;
      @media (max-width: 1130px) {
        top: 37px;
        left: 179px;
      }
    }
    &12 {
      top: 51px;
      left: 207px;
      @media (max-width: 1130px) {
        top: 56px;
        left: 182px;
      }
    }
    &13 {
      top: 72px;
      left: 208px;
      @media (max-width: 1130px) {
        top: 74px;
        left: 184px;
      }
    }
    &14 {
      top: 92px;
      left: 207px;
      @media (max-width: 1130px) {
        top: 92px;
        left: 183px;
      }
    }
    &15 {
      top: 113px;
      left: 205px;
      @media (max-width: 1130px) {
        top: 110px;
        left: 181px;
      }
    }
    &16 {
      top: 133px;
      left: 194px;
      @media (max-width: 1130px) {
        top: 128px;
        left: 171px;
      }
    }
    &17 {
      top: 136px;
      left: 173px;
      @media (max-width: 1130px) {
        top: 132px;
        left: 153px;
      }
    }
    &18 {
      top: 139px;
      left: 153px;
      @media (max-width: 1130px) {
        top: 134px;
        left: 135px;
      }
    }
    &19 {
      top: 141px;
      left: 133px;
      @media (max-width: 1130px) {
        top: 136px;
        left: 117px;
      }
    }
    &20 {
      top: 143px;
      left: 112px;
      @media (max-width: 1130px) {
        top: 138px;
        left: 99px;
      }
    }
    &21 {
      top: 143px;
      left: 92px;
      @media (max-width: 1130px) {
        top: 137px;
        left: 81px;
      }
    }
    &22 {
      top: 141px;
      left: 71px;
      @media (max-width: 1130px) {
        top: 136px;
        left: 62px;
      }
    }
    &23 {
      top: 139px;
      left: 50px;
      @media (max-width: 1130px) {
        top: 134px;
        left: 45px;
      }
    }
    &24 {
      top: 136px;
      left: 30px;
      @media (max-width: 1130px) {
        top: 132px;
        left: 27px;
      }
    }
    &25 {
      top: 133px;
      left: 10px;
      @media (max-width: 1130px) {
        top: 128px;
        left: 8px;
      }
    }
    &26 {
      top: 112px;
      left: 2px;
      @media (max-width: 1130px) {
        top: 110px;
        left: 2px;
      }
    }
    &27 {
      top: 91px;
      left: 0px;
      @media (max-width: 1130px) {
        top: 92px;
        left: 0px;
      }
    }
    &28 {
      top: 71px;
      left: 0px;
      @media (max-width: 1130px) {
        top: 74px;
        left: -2px;
      }
    }
    &29 {
      top: 51px;
      left: 2px;
      @media (max-width: 1130px) {
        top: 56px;
        left: 1px;
      }
    }
    &30 {
      top: 30px;
      left: 4px;
      @media (max-width: 1130px) {
        top: 38px;
        left: 4px;
      }
    }
  }
}

div .text {
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 0;
  text-shadow:
    -3px 2px 0px #bd4036,
    0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 1130px) {
    font-size: 26px;
  }

  &Number {
    font-size: 48px;
  }
}

@keyframes pulseAnimation {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.3);
  }
  100% {
    filter: brightness(1);
  }
}
